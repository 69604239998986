#auth-passowrd-reset {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 0;
}

#auth-passowrd-reset #contents {
  width: 100%;
  max-width: 440px;
  padding: 15px;
  margin: auto;
}

#auth-passowrd-reset section {
  margin: auto;
  width: 100%;
}

#auth-passowrd-reset .card {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

#auth-passowrd-reset .card-header {
  background: #000;
  text-align: center;
}

#auth-passowrd-reset .card-header img {
  margin: 15px 0;
  max-width: 240px;
  width: 100%;
}
