html,
body {
  height: 100%;
  word-wrap: break-word;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}

#root {
  min-height: 100%;
  background-color: #e2e2e2;
}

#root > div {
  min-height: 100%;
}

.hm-400 {
  max-height: 400px;
}

.hm-400 canvas {
  max-height: 400px;
}

.overflow-y-auto {
  overflow-y: scroll;
  overflow-x: auto;
}

.required {
  margin-left: 5px;
  background-color: red;
  padding: 2px;
  color: white;
  font-size: 80%;
}

.prewrap {
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
}

/*

#sidebar {
  max-width: 70px;
  min-height: 100vh;
  background: #0c423c;
  padding-right: 0;
}

#sidebar .header {
  height: 80px;
  background: #0c423c;
  text-align: center;
  border-bottom: 1px solid #fff;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

#sidebar .header img {
  max-width: 160px;
  width: 100%;
  margin: 0 auto;
}

#sidebar .nav-link {
  height: 90px;
  border-bottom: 1px solid #fff;
  align-items: center;
  display: flex;
  color: #fff;
}

#sidebar .nav-link.active {
  background-color: #69c0bc;
  color: 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

#sidebar .nav-link a {
  color: #fff;
}

#sidebar .nav-link .nav-icon {
  min-width: 55px;
  min-height: 55px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#sidebar .nav-link .nav-icon {
  margin-right: 15px;
}

#sidebar .nav-link .nav-icon.home {
  background-image: url("../img/icon_home.png");
}

#sidebar .nav-link .nav-icon.admin {
  background-image: url("../img/icon_admin.png");
}

#sidebar .nav-link .nav-icon.user {
  background-image: url("../img/icon_user.png");
}

#sidebar .nav-link .nav-icon.license {
  background-image: url("../img/icon_license.png");
}

#sidebar .nav-link .nav-icon.category {
  background-image: url("../img/icon_category.png");
}

#sidebar .nav-link .nav-icon.movie {
  background-image: url("../img/icon_movie.png");
}

#sidebar .nav-link .nav-icon.notice {
  background-image: url("../img/icon_movie.png");
}

#sidebar .nav-link.active .nav-icon.home {
  background-image: url("../img/icon_home_active.png");
}

#sidebar .nav-link.active .nav-icon.admin {
  background-image: url("../img/icon_admin_active.png");
}

#sidebar .nav-link.active .nav-icon.user {
  background-image: url("../img/icon_user_active.png");
}

#sidebar .nav-link.active .nav-icon.license {
  background-image: url("../img/icon_license_active.png");
}

#sidebar .nav-link.active .nav-icon.category {
  background-image: url("../img/icon_category_active.png");
}

#sidebar .nav-link.active .nav-icon.movie {
  background-image: url("../img/icon_movie_active.png");
}

#sidebar .nav-link.active .nav-icon.notice {
  background-image: url("../img/icon_movie_active.png");
}

*/

.header {
  background-color: #000;
  color: #fff;
}

.header .header-logo {
  max-height: 38px;
}

.header .logout {
  background-color: #fff;
  color: #0c423c;
  color: 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  border: none;
}

.header a[role="button"] {
  color: #000;
}

.breadcrumbs {
  background-color: #fff;
  margin-bottom: 20px;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  padding: 5px 10px;
}

#contents {
  min-height: 100%;
  margin: 20px 3%;
}

#contents .header .logout:hover {
  background-color: #177c70;
  color: 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  border: none;
}

#contents .btn-primary,
.modal-body .btn-primary {
  background-color: #5f5f5f;
  border: none;
}

#contents .sub-title {
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 1em;
  border-bottom: 5px solid #e2e2e2;
}

#contents .contents-box {
  position: relative;
  background-color: #ffffff;
  padding: 1em;
  border-radius: 5px;
  margin-bottom: 2em;
  overflow: hidden;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.footer {
  height: 40px;
  line-height: 40px;
  text-align: right;
  padding: 0 3%;
  background-color: #000;
  color: #fff;
}

/* thumbnail */

.thumbnail-licence {
  position: relative;
  overflow: hidden;
}

.thumbnail-licence .licence {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ccc;
  text-align: center;
  font-weight: bold;
  z-index: 100;
  pointer-events: none;
}

.thumbnail-licence .licence .licence-lock-wrap,
.thumbnail-licence .licence .licence-play-wrap,
.thumbnail-licence-player .licence .licence-lock-wrap {
  width: fit-content;
  height: fit-content;
  position: absolute;
  inset: 0;
  margin: auto;
}
.thumbnail-licence .lock-icon {
  color: #f90;
}

.thumbnail-content,
.thumbnail-content-player {
  display: block;
  position: relative;
  border: 1px solid #ccc;
  text-align: center;
}
.thumbnail-content::before,
.thumbnail-content-player::before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.thumbnail-content .thumbnail,
.thumbnail-content-player .thumbnail {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  margin: auto;
}
.thumbnail-content .playtime {
  position: absolute;
  display: inline-block;
  right: 2px;
  bottom: 2px;
  background-color: #333;
  color: #fff;
  padding: 1px 3px;
  font-size: 12px;
}

.thumbnail-licence-player {
  position: relative;
  overflow: hidden;
}

.thumbnail-licence-player .lock-icon {
  color: #f90;
}

.play-icon {
  color: #99ffff;
}

.thumbnail-licence-player .licence {
  width: 100%;
  height: 100%;
  min-height: 120 px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ccc;
  text-align: center;
  font-weight: bold;
  z-index: 100;
}

.modal-backdrop {
  background-color: #7d7a7a;
}
