#about .background-image {
  background-image: url("../img/member-register.png");
}

#about .signin-image {
  background-image: url("../img/signin-image.png");
}

#about .signin-image {
  background-image: url("../img/top-image.png");
}

#about .about-image {
  background-image: url("../img/surfnation-lesson1.png");
}

#about .about-image {
  background-image: url("../img/surfnation-lesson2.png");
}
#about .about-image {
  background-image: url("../img/surfnation-lesson3.png");
}

#about h4 {
  font-weight: 900;
}

#about .mv-about {
  filter: brightness(50%);
}
#about .mv-about-title {
  inset: 40% 10% 0 auto;
  z-index: 1;
  font-feature-settings: "palt";
}
@media screen and (max-width: 768px) {
  #about .mv-about-title {
    text-align: center;
    width: 80%;
    inset: initial;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
#about .register-img {
  width: auto;
  height: 140px;
  padding-left: 1em;
}

#about .signin-img {
  width: auto;
  height: 340px;
  padding-left: 1em;
}

#about .top-img {
  width: auto;
  height: 160px;
  padding-left: 1em;
}
#about .about-image {
  width: auto;
  height: 560px;
}

/* #about .step {
  color: rgb(108, 106, 106);
  font-weight: bold;
  font-size: 20px;
  margin: 1em 0 2em 1em;
  background: #fff;
} */

#about h5 {
  display: inline-block;
  padding: 0.5em 0 0.1em;
  border-bottom: 2px solid #5bd7c8;
  text-align: center;
}

#about .about-footer {
  background-color: antiquewhite;
  padding: 2em;
  font-weight: bold;
}

#about .online-lesson-list {
  display: inline-block;
  list-style-type: none;
  background-color: rgb(255, 249, 249);
  font-weight: bold;
}

#about .register-step img {
  max-width: 100%;
  max-height: 350px;
}

#about .section-margin {
  margin-bottom: 100px;
}
