#forgot-password {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 0;
}

#forgot-password #contents {
  width: 100%;
  max-width: 440px;
  padding: 15px;
  margin: auto;
}

#forgot-password section {
  margin: auto;
  width: 100%;
}

#forgot-password .card {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

#forgot-password .card-header {
  background: #000;
  text-align: center;
}

#forgot-password .card-header img {
  margin: 15px 0;
  max-width: 240px;
  width: 100%;
}
